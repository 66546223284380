export const sidebarConfig = {
  superadmin: ['CustomerManagement', 'UserManagement', 'ChangePassword', 'AddDevice', 'DeviceLocation', 'AppVersion'],
  systemspecialist: ['Dashboard', 'Location', 'UserManagement', 'Report', 'ChangePassword',
    'Device', 'AddDevice', 'DeviceLocation', 'EmailConfig',
    // 'Vendor',
  ],
  admin: ['Dashboard', 'Location', 'UserManagement',
    // 'Vendor',
    'Report', 'ChangePassword', 'AddDevice', 'DeviceLocation', 'EmailConfig'],
  manager: ['Dashboard', 'Location', 'UserManagement',
    // 'Vendor',
    'ChangePassword', 'AddDevice', 'DeviceLocation', 'Report'],
  user: ['Dashboard', 'Location', 'ChangePassword', 'AddDevice', 'DeviceLocation'],
};
// 'GasCylinder'
export const crudConfig = {
  dashboard: {
    superadmin: {
      view: true,
      add: true,
      edit: true,
      delete: true,
    },
    systemspecialist: {
      view: true,
      add: true,
      edit: true,
      delete: true,
    },
    admin: {
      view: true,
      add: false,
      edit: true,
      delete: true,
    },
    manager: {
      view: true,
      add: true,
      edit: true,
      delete: true,
    },
    user: {
      view: true,
      add: false,
      edit: false,
      delete: true,
    },
  },
  location: {
    systemspecialist: {
      view: true,
      add: true,
      edit: true,
      delete: true,
    },
    admin: {
      view: true,
      add: false,
      edit: true,
      delete: false,
    },
    manager: {
      view: true,
      add: false,
      edit: true,
      delete: false,
    },
    user: {
      view: true,
      add: false,
      edit: false,
      delete: false,
    },
  },
  emailconfig: {
    systemspecialist: {
      view: true,
      add: true,
      edit: true,
      delete: true,
    },
    admin: {
      view: true,
      add: false,
      edit: true,
      delete: false,
    },
    manager: {
      view: true,
      add: false,
      edit: false,
      delete: false,
    },
    user: {
      view: true,
      add: false,
      edit: false,
      delete: false,
    },
  },
  usermanagement: {
    superadmin: {
      view: true,
      add: true,
      edit: true,
      delete: true,
    },
    systemspecialist: {
      view: true,
      add: true,
      edit: true,
      delete: true,
    },
    admin: {
      view: true,
      add: true,
      edit: true,
      delete: true,
    },
    manager: {
      view: true,
      add: false,
      edit: true,
      delete: false,
    },
    user: {
      view: true,
      add: false,
      edit: false,
      delete: false,
    },
  },
  vendor: {
    systemspecialist: {
      view: true,
      add: true,
      edit: true,
      delete: true,
    },
    admin: {
      view: true,
      add: true,
      edit: true,
      delete: true,
    },
    manager: {
      view: true,
      add: true,
      edit: true,
      delete: false,
    },
    user: {
      view: true,
      add: false,
      edit: false,
      delete: false,
    },
  },
  gascylinder: {
    superadmin: {
      view: false,
      add: false,
      edit: false,
      delete: false,
    },
    systemspecialist: {
      view: true,
      add: true,
      edit: true,
      delete: true,
    },
    admin: {
      view: true,
      add: true,
      edit: true,
      delete: true,
    },
    manager: {
      view: false,
      add: false,
      edit: false,
      delete: false,
    },
    user: {
      view: false,
      add: false,
      edit: false,
      delete: false,
    },
  },
  changepassword: {
    superadmin: {
      view: true,
      add: true,
      edit: true,
      delete: true,
    },
    systemspecialist: {
      view: true,
      add: true,
      edit: true,
      delete: true,
    },
    admin: {
      view: true,
      add: true,
      edit: true,
      delete: true,
    },
    manager: {
      view: true,
      add: true,
      edit: true,
      delete: false,
    },
    user: {
      view: true,
      add: false,
      edit: false,
      delete: false,
    },
  },
  device: {
    superadmin: {
      view: true,
      add: true,
      edit: true,
      delete: true,
    },
    systemspecialist: {
      view: true,
      add: true,
      edit: true,
      delete: true,
    },
    admin: {
      view: true,
      add: false,
      edit: true,
      delete: true,
    },
    manager: {
      view: true,
      add: false,
      edit: true,
      delete: false,
    },
    user: {
      view: true,
      add: false,
      edit: false,
      delete: false,
    },
  },
  report: {
    superadmin: {
      view: true,
      add: true,
      edit: true,
      delete: true,
    },
    systemspecialist: {
      view: true,
      add: true,
      edit: true,
      delete: true,
    },
    admin: {
      view: true,
      add: false,
      edit: true,
      delete: true,
    },
    manager: {
      view: true,
      add: true,
      edit: true,
      delete: false,
    },
    user: {
      view: true,
      add: false,
      edit: false,
      delete: false,
    },
  },
  devicelocation: {
    systemspecialist: {
      view: true,
      add: true,
      edit: true,
      delete: true,
      changeDeviceMode: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
    },
    admin: {
      view: true,
      add: false,
      edit: true,
      delete: false,
      changeDeviceMode: {
        view: true,
        add: false,
        edit: true,
        delete: false,
      },
    },
    manager: {
      view: true,
      add: false,
      edit: true,
      delete: false,
      changeDeviceMode: {
        view: false,
        add: false,
        edit: true,
        delete: false,
      },
    },
    user: {
      view: true,
      add: false,
      edit: false,
      delete: false,
      changeDeviceMode: {
        view: false,
        add: false,
        edit: false,
        delete: false,
      },
    },
  },
};
